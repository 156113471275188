@import url("https://unpkg.com/react-sidebar-ui@1.2.0/dist/index.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --primaryColor: #ff004c;
  --secondaryColor: #ffe7ef;
  --darkSecondarycolor: #fec6d8;
  --lightBorder: #f5f5f5;
  --darkBorder: #ccc;
}
body,
html {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-text {
  font-size: 1rem;
}

.login-image {
  height: 100vh;
  max-width: 100%;
}

.login-logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-right: 0.5rem;
}
.sider-logo {
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  margin-right: 0.5rem;
}
.sider-title {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.2rem;
}
.title {
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin: 1rem 0.5rem;
  justify-content: space-between;
}

.midtitle {
  font-size: 1.5rem;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.sub-title {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding: 5px;

  border-radius: 5px;
  background-color: var(--secondaryColor);
  margin: 1rem 0.5rem;
  text-wrap: wrap;
}

.listBox {
  max-height: 10rem;
  width: max-content;
  min-width: 10rem;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}
.border-Radius-5px {
  border-radius: 5px;
}

.height-100p {
  height: 100%;
}

.block-button-text {
  font-size: 1rem;
  font-weight: 550;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.ant-input-group > .ant-input:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.login-margin {
  margin: 0rem 9rem;
  position: relative;
}
.signup-text {
  text-align: right;
}
.back-button {
  color: var(--primaryColor);
}
.marginTop-1rem {
  margin-top: 1rem;
}
.tab-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.tab-item {
  border: 1px solid var(--secondaryColor);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 1rem;
  font-weight: 600;
}

.tab-item-round {
  border: 1px solid var(--secondaryColor);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  cursor: pointer;
  margin-left: 1rem;
  font-weight: 600;
}

.selected-tab-item {
  color: white;
  background-color: var(--primaryColor);
}

.date-picker {
  border-radius: 5px;
  width: 100%;
}

.ant-checkbox-group {
  display: flex;
  gap: 1rem;
}
.select-filter {
  border-radius: 5px;
}
.ant-card {
  background-color: white;
}

.text-white {
  color: white;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
}

.carousel-img {
  height: 15rem;
  border-radius: 20px;
  margin-left: 1rem;
  background-color: #8bad81;
  max-width: 360px;
}

.carousel-img img {
  border-radius: 20px;
  background-color: #8bad81;
  object-fit: contain;
  height: 100%;
  right: 20;
  max-width: 460px;
}

.navbar {
  background-color: var(--primaryColor);
  color: white;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  min-height: 64px;
  position: fixed;
  z-index: 2;
}

.navbar-title {
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 900;
}
.content-wrapper {
  margin-top: 64px;
}
.form-item-width {
  min-width: 290px !important;
}

.menu {
  box-shadow: 0 0 0 rgba(255, 229, 233, 0);
}
.menu-icon {
  box-shadow: 0 0 0 rgba(255, 229, 233, 0);
}

.col-title-color {
  color: var(--primaryColor);
}

.profile-item-spacing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profile-item-width {
  width: 120px;
}
.ant-drawer .ant-drawer-body {
  padding: 0;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
  height: 200px;
}

@media (min-width: 992px) {
  .menu-icon {
    display: none;
  }
}

.info-card {
  border-radius: 5px;
  text-align: center;
}

.div-flex-row {
  display: flex;
  flex-direction: row;
  padding: 0.8rem;
  gap: 1rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.flex-column {
  flex-direction: column;
}

/* .ant-carousel .slick-slide img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 20px;
} */

/* Header css */

.header-profile {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-right: 1rem;
  margin-top: 0.7rem;
  border-radius: 50%;
}
.header-page-title {
  visibility: hidden;
}

/* Dashboard */

.scroll-horizontally {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-horizontally::-webkit-scrollbar {
  display: none;
}

.scroll-right-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 1;
}

.scroll-left-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  z-index: 1;
}

/* user profile card */

.user-profile-card {
  margin: 8px;
  width: 22rem;
  height: 16rem;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 5px;
}
.user-profile-card-view-all {
  margin: 8px;
  width: 9rem;
  height: 16rem;
  font-size: large;
  color: var(--primaryColor);
  justify-content: center;
  align-items: center;
  display: flex;
}

.all-profile-card-meta {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 92vw;
  max-width: 390px;
  padding: 20px;
  bottom: 59px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 1;
}

.profile-view-action-background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  width: 92vw;
  max-width: 390px;
  padding: 8px;
  height: 60px;
  bottom: 20px;
  border-radius: 50px;
  left: 40%;
  z-index: 1;
}

.all-profile-card-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background-color: rgba(0, 0, 0, 1);
  padding: 0.5rem;
  margin-top: -0.5rem;
  z-index: 2;
}

.button-size {
  font-size: 1.5rem;
  color: white;
}

.home-banner-img {
  height: 200px;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  object-fit: cover;
}

.button-size-selected {
  font-size: 1.5rem;
  color: wheat;
}
.w-100 {
  width: "100%";
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}

.myprofile-view {
  background-color: white;
  padding: 1rem;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

/* Responsive styles for screens smaller than 600px (adjust as needed) */
@media screen and (max-width: 600px) {
  .login-margin {
    margin: 0rem 1rem;
  }
  .header-page-title {
    visibility: visible;
    color: white;
    font-size: 1rem;
    margin-top: 16px;
    margin-left: 50px;
  }

  .profile-view-action-background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    width: 92vw;
    max-width: 390px;
    padding: 8px;
    height: 60px;
    bottom: 20px;
    border-radius: 50px;
    left: 10px;
    z-index: 1;
  }
}

/* Responsive styles for screens smaller than 768px (adjust as needed) */
@media only screen and (max-width: 768px) {
  .login-image {
    height: 50vh;
  }
  .login-margin {
    margin: 0rem 1.5rem;
  }
  .signup-text {
    text-align: center;
    margin-top: 20;
  }
  .navbar-title {
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    margin-right: 20px;
    font-weight: 900;
  }
  .block-button-text {
    font-size: 1.2em;
    font-weight: 550;
  }
  .form-item-width {
    min-width: 170px !important;
  }
  .myprofile-view {
    background-color: white;
    padding: 1rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
}
.header-fixed {
  h4 {
    margin-top: 15px;

    margin-bottom: 20px;
  }
}

* {
  font-family: "Montserrat", sans-serif;
}

._2a97v {
  background-color: crimson;
}

._mra1C {
  background-color: white;
}

._13dNw {
  width: 250px;
  height: 100vh;
  background-color: deepskyblue;
  color: white;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

._VrCvP {
  width: 95%;
  padding: 10px 0;
  padding-left: 12px;
  background-color: deepskyblue;
  cursor: pointer;
  text-align: left;
}

._VrCvP:hover {
  background-color: #0377da;
  padding: 14px 0;
  padding-left: 12px;
  transition: all 0.3s ease-in-out;
}

._2Vept {
  padding-right: 10px;
  display: inline-block;
  width: 20%;
  font-size: 15px;
}

._26ojv {
  background-color: #3960d0;
  color: white;
}

._2nNpb:hover {
  background-color: #609beb;
  color: white;
}

._36YqV {
  background-color: #fffeff;
  color: black;
}

._8tJF8:hover {
  background-color: #f6f6f6;
}

._2Ll57 {
  background-color: #1c1e2a;
  color: white;
}

._3HbC6:hover {
  background-color: #2c2f3e;
  color: white;
}

._GubVm {
  text-align: left;
  cursor: pointer;
}

._3ow2- {
  margin: 0 1em;
}

._2fnX0 {
  display: none;
}

._-s3Rh {
  display: block;
  position: relative;
}

._3qnVN {
  width: 100px;
  margin-top: 1em;
}

._2_oI1 {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 22px;
  cursor: pointer;
}

._2Q5t5 {
  width: 85px;
}

input {
  padding: 12px 20px;
  margin: 15px 0;
  box-sizing: border-box;
}

._17SmE {
  left: 0;
}

._1NTmL {
  right: 0;
}

._1vjRi {
  background-color: #fd7e65;
  color: white;
}

._2PHvo:hover {
  background-color: #fd9768;
}

._1tllo {
  background-color: #a771e4;
  color: white;
}

._lbp0_:hover {
  background-color: #e26fcf;
}

._2maJj {
  background-color: #1da4f4;
  color: white;
}

._1NfpZ:hover {
  background-color: #16bcde;
}
._2TMpC {
  float: right;
}
